@import "./0_mediaquery";
@import "./variables";

.landing {
  height: 100vh;
  overflow-y: auto;
  background-color: $bg;

  .landing_hero {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    min-height: 90vh;
    background-image: url("../assets/images/bg.png");
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;

    .nhero {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1em;
      max-width: 85%;
      width: 100%;
      margin: auto;

      h1 {
        color: #404a3d;
        margin-bottom: 1em;
      }

      p {
        line-height: 2;
        color: #404a3d;
      }

      img {
        max-width: 85%;
        width: 100%;
        object-fit: contain;
      }
    }
    @include maxwidth(mobile) {
      .nhero {
        padding: 0 1fr !important;
        grid-template-columns: 1fr !important;
      }
    }
    @include maxwidth(tablet) {
      .nhero {
        padding: 0 1fr !important;
        grid-template-columns: 1fr !important;
      }
    }
  }

  .about {
    padding: 0;

    .container {
      .cover {
        background-image: linear-gradient(
          $bg 0%,
          $bg 70%,
          rgba($color: #111b21, $alpha: 0.1) 100%
        );
      }

      .item {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 3em;
        padding: 4em 0;
        max-width: 85%;
        width: 100%;
        margin: auto;
      }

      img {
        width: 200px;
        height: 200px;
        object-fit: contain;
        object-position: center;
        display: block;
        margin: auto;
      }

      h4 {
        color: $primary;
        font-size: medium;
        margin: 10px 0 10px 0;
        font-weight: 400;
      }

      p {
        line-height: 1.7;
        font-size: medium;
        font-weight: 400;
        color: gray;
        margin: 10px 0 10px 0;
      }
    }

    @include maxwidth(mobile) {
      .item {
        padding: 0 1fr !important;
        grid-template-columns: 1fr !important;
      }
    }
    @include maxwidth(tablet) {
      .item {
        padding: 0 1fr !important;
        grid-template-columns: 1fr !important;
      }
    }
  }
}

@keyframes zoom {
  0% {
    scale: 0.8;
  }
  100% {
    scale: 1;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
