@import "./_variables";
@import "./_0_mediaquery";

.terms {
  height: 100vh;
  overflow-y: auto;
  position: relative;

  .tnc {
    .container {
      max-width: 85%;
      width: 100%;
      margin: auto;
      padding-bottom: 2em;

      h1 {
        color: $primary;
        margin-top: 1em;
        font-size: x-large;
      }

      h2 {
        margin: 1em 0 5px 0;
        font-weight: 600;
        color: $primary;
        font-size: large;
      }

      h4 {
        margin: 10px 0 5px 0;
        font-weight: 600;
        color: $primary;
        font-size: large;
        font-weight: 500;
      }

      p {
        padding: 5px 0 10px 0;
        color: #606060;
        line-height: 1.3;
      }
      h5 {
        font-weight: 500;
        font-size: medium;
        color: $primary;
        padding: 5px 0 5px 0;
      }
    }
  }
}
